import { Component } from '@angular/core';

@Component({
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      :host::ng-deep mat-card {
        margin: 0 var(--global-padding) var(--global-padding);
      }
    `,
  ],
})
export class KnowledgeBaseLayoutComponent {}
