<app-header />
<mat-card>
  <mat-card-header>
    <mat-card-title
      >{{ tempTaxon?.taxonName ?? 'Overview' }}&nbsp;<app-taxon-in-use-badge [taxon]="taxon"
    /></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (taxon) {
      <app-edit
        [formGroup]="formGroup"
        permission="taxon.edit"
        (save)="onSave($event)"
        #editComponent
      >
        <div class="row">
          <div class="input-group">
            <mat-form-field>
              <mat-label>Taxon Name</mat-label>
              <input matInput type="text" formControlName="taxonName" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>CPR Taxon ID</mat-label>
              <input matInput type="number" formControlName="cprTaxonId" />
              @if (formGroup.controls.cprTaxonId.enabled) {
                <button
                  mat-icon-button
                  matIconSuffix
                  matTooltip="Auto-increment"
                  (click)="$event.stopPropagation(); autoIncrement()"
                >
                  <mat-icon>bolt</mat-icon>
                </button>
              }
            </mat-form-field>

            <mat-form-field>
              <mat-label>Accepted ID</mat-label>
              <input matInput type="number" formControlName="acceptedId" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Aphia ID</mat-label>
              <input matInput type="number" formControlName="aphiaId" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>ITIS Code</mat-label>
              <input matInput type="number" formControlName="itisCode" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Parent Taxon IDs (comma separated)</mat-label>
              <input matInput formControlName="parentTaxonIdList" />
            </mat-form-field>
          </div>

          <div class="input-group">
            <mat-form-field>
              <mat-label>Counting Method</mat-label>
              <mat-select name="countingMethod" formControlName="countingMethod">
                <mat-option value="EYECOUNT">Eye Count</mat-option>
                <mat-option value="TRAVERSE">Traverse</mat-option>
                <mat-option value="PHYTO">Phyto</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Classification</mat-label>
              <input matInput readonly type="text" formControlName="rank" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Life Stage</mat-label>
              <input matInput type="text" formControlName="lifeStage" />
            </mat-form-field>

            <mat-checkbox type="checkbox" formControlName="routineAnalysis">
              Routine analysis?
            </mat-checkbox>

            <mat-checkbox type="checkbox" formControlName="isOnlyEverPlussed">
              Is only ever plussed?
            </mat-checkbox>
          </div>

          <div class="input-group">
            <mat-form-field>
              <mat-label>Comments</mat-label>
              <textarea matInput formControlName="comments"></textarea>
            </mat-form-field>
          </div>
        </div>

        @if (taxon.aphiaId) {
          <a
            mat-button
            [href]="'https://www.marinespecies.org/aphia.php?p=taxdetails&id=' + taxon.aphiaId"
            target="_blank"
          >
            <mat-icon>open_in_new</mat-icon>
            <span>WoRMS Taxon Details</span>
          </a>
        }
      </app-edit>
    }
  </mat-card-content>
</mat-card>
