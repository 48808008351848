import { Component } from '@angular/core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { Router } from '@angular/router';

@Component({
  template: `
    <div class="buttons-container">
      <button mat-flat-button (click)="createTaxa()" [checkPermission]="['taxon', 'edit']">
        Create
      </button>
    </div>
    <app-knowledge-base-search></app-knowledge-base-search>
    <suvo-core-loading-spinner *ngIf="!taxon"></suvo-core-loading-spinner>
    <app-taxon-lineage *ngIf="taxon" [isTopLevelTaxon]="true" [taxon]="taxon"></app-taxon-lineage>
  `,
  styles: [
    `
      .buttons-container {
        position: absolute;
        top: 10px;
        right: 20px;
      }
      .no-permission {
        display: none;
      }
    `,
  ],
})
export class KnowledgeBaseOverviewScreenComponent {
  taxon: ITaxon;

  constructor(
    private readonly taxaDirectory: TaxonService,
    private readonly router: Router,
  ) {
    this.taxaDirectory
      .get('topleveltaxon')
      .then((taxonChildren) => (this.taxon = { taxonChildren } as ITaxon));
  }

  createTaxa() {
    this.router.navigateByUrl('knowledge-base/create');
  }
}
