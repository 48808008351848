import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ITableDataSource } from '@suvo-bi-lib';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { ITableSortDirection } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';

@Component({
  selector: 'app-knowledge-base-search',
  styleUrls: ['./knowledge-base-search.component.scss'],
  template: `
    <app-header />

    <mat-card>
      <mat-card-header>
        <mat-card-title>Taxa Directory Search</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <form [formGroup]="searchForm">
          <div style="display: flex; align-items: center; gap: 16px">
            <mat-form-field>
              <mat-label>Search</mat-label>
              <input matInput formControlName="searchControl" (keyup)="onSearchChange()" />
            </mat-form-field>
          </div>
        </form>

        <div *ngIf="searchResults">
          <h2>Search Results</h2>
          <div *ngFor="let taxon of searchResults.data">
            <div class="search-result">
              <a [routerLink]="['/knowledge-base', taxon._id]">
                {{ taxon.taxonName }} ({{ taxon.cprTaxonId }}) </a
              >&nbsp;<app-taxon-in-use-badge [taxon]="taxon" />
            </div>
          </div>
          <p *ngIf="searchResults.count >= 50">
            Results limited to 50, please refine your search query.
          </p>
          <p *ngIf="searchResults.count == 0">No results found.</p>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class KnowledgeBaseSearchComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  searchResults: ITableDataSource<ITaxon>;

  private searchSubject: Subject<string> = new Subject();
  searchForm: FormGroup = new FormGroup({
    searchControl: new FormControl<string>(undefined),
  });

  constructor(
    router: Router,
    private readonly taxaDirectory: TaxonService,
  ) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.searchResults = undefined;
      }
    });

    this.searchSubject.pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(() => {
      this.doSearch();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSearchChange() {
    this.searchSubject.next(this.searchForm.controls.searchControl.value);
  }

  async doSearch() {
    this.searchResults = await this.taxaDirectory.getPaginated('', {
      search: this.searchForm.controls.searchControl.value,
      pagination: { pageSize: 50, pageIndex: 0 },
      sort: {
        active: 'taxonName',
        direction: ITableSortDirection.Ascending,
      },
    });
  }
}
