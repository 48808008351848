<mat-card>
  <mat-card-header>
    <mat-card-title>Taxon History</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="!taxon?.history?.length">Taxon unchanged.</div>
    <div *ngIf="taxon?.history?.length">
      <div *ngFor="let historyItem of taxon.history">
        <strong>{{ historyItem.fieldName }}</strong
        >&nbsp;=&nbsp;<span>{{ historyItem.newValue }}</span
        >&nbsp;<span>{{ historyItem.realDateOfChange | date }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
