import { Component, Input, OnChanges } from '@angular/core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';

@Component({
  selector: 'app-taxon-lineage',
  templateUrl: './taxon-lineage.component.html',
  styleUrls: ['./taxon-lineage.component.scss'],
})
export class TaxonLineageComponent implements OnChanges {
  @Input() isTopLevelTaxon: boolean;
  @Input() taxon: ITaxon;

  parents: ITaxon[];

  constructor(private readonly taxonService: TaxonService) {}

  async ngOnChanges() {
    if (this.taxon.parentTaxonIds && this.taxon.parentTaxonIds.length > 0) {
      this.parents = this.taxon
        ? (
            await Promise.all(
              this.taxon.parentTaxonIds.map((id) => this.taxonService.getByCprId(id)),
            )
          ).filter((t) => t)
        : [];
    } else {
      this.parents = [];
    }
  }

  async refresh() {
    if (this.taxon.parentTaxonIds && this.taxon.parentTaxonIds.length > 0) {
      this.parents = this.taxon
        ? (
            await Promise.all(
              this.taxon.parentTaxonIds.map((id) => this.taxonService.getByCprId(id)),
            )
          ).filter((t) => t)
        : [];
    } else {
      this.parents = [];
    }
  }
}
