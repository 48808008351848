import { Component, computed, inject, input } from '@angular/core';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { ITaxon } from '../../taxon/taxon.interface';
import { TaxonService } from '../../taxon/taxon.service';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [CommonModule, MatIcon, MatTooltip],
  selector: 'app-taxon-in-use-badge',
  styles: `
    mat-icon {
      font-size: 18px;
      position: relative;
      top: 7px;
    }
  `,
  template: `
    <mat-icon *ngIf="inUse()" [style.color]="'rgb(92 179 167)'" matTooltip="In use"
      >check_circle</mat-icon
    >
    <mat-icon *ngIf="!inUse()" [style.color]="'#ba1a1a50'" matTooltip="Not in use">block</mat-icon>
  `,
})
export class TaxonInUseBadgeComponent {
  private readonly taxonService = inject(TaxonService);

  readonly taxon = input.required<ITaxon>();
  readonly inUse = computed(() => this.taxonService.getTaxaInUse(this.taxon()));
}
