import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TCountingMethodType } from '@cpr/shared/counting-method';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { lastValueFrom } from 'rxjs';
import { environment } from '$env';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';

@Component({
  selector: 'app-taxon-overview',
  templateUrl: './taxon-overview.component.html',
  styleUrls: ['./taxon-overview.component.scss'],
})
export class TaxonOverviewComponent implements OnChanges {
  @ViewChild('editComponent') editComponent: EditComponent;

  @Input() isTopLevelTaxon!: boolean;
  @Input() taxon: ITaxon;

  @Output() save = new EventEmitter<void>();

  tempTaxon: ITaxon;

  readonly formGroup = new FormGroup({
    taxonName: new FormControl<string>(undefined, Validators.required),
    rank: new FormControl<string>(undefined),
    cprTaxonId: new FormControl<number>(undefined, Validators.required),
    aphiaId: new FormControl<number>(undefined),
    acceptedId: new FormControl<number>(undefined, Validators.required),
    itisCode: new FormControl<number>(undefined),
    routineAnalysis: new FormControl<boolean>(undefined),
    isOnlyEverPlussed: new FormControl<boolean>(undefined),
    countingMethod: new FormControl<TCountingMethodType>(undefined, Validators.required),
    lifeStage: new FormControl<string>(undefined),
    comments: new FormControl<string>(undefined),
    parentTaxonIdList: new FormControl<string>(undefined),
  });

  constructor(
    private readonly http: HttpClient,
    private readonly taxaDirectory: TaxonService,
    private readonly router: Router,
  ) {}

  ngOnChanges() {
    this.tempTaxon = JSON.parse(JSON.stringify(this.taxon));

    if (this.tempTaxon) {
      this.formGroup.reset();
      this.formGroup.patchValue(this.tempTaxon);

      if (this.tempTaxon.parentTaxonIds) {
        this.formGroup.controls.parentTaxonIdList.setValue(this.tempTaxon.parentTaxonIds.join(','));
      }
    }
  }

  async onSave($event: Partial<ITaxon>) {
    Object.assign(this.tempTaxon, $event);

    if (this.formGroup.controls.parentTaxonIdList.value) {
      let ids = this.formGroup.controls.parentTaxonIdList.value.split(',');

      let parentIdList = [];

      for (let id of ids) {
        const idAsNumber = parseInt(id, 10);

        if (!isNaN(idAsNumber)) {
          parentIdList.push(idAsNumber);
        } else {
          this.formGroup.controls.parentTaxonIdList.setErrors({ message: 'Invalid parent IDs' });
          return;
        }
      }

      this.tempTaxon.parentTaxonIds = parentIdList;
    }

    if (this.tempTaxon._id) {
      await this.taxaDirectory.patchOne('', this.tempTaxon._id, this.tempTaxon);

      await this.taxaDirectory.refreshHierarchy();

      this.save.next();
    } else {
      await this.taxaDirectory.postOne('', this.tempTaxon);

      await this.taxaDirectory.refreshHierarchy();

      this.router.navigateByUrl('knowledge-base');
    }

    this.editComponent.markAsUpdated();
  }

  async autoIncrement() {
    this.formGroup.controls.cprTaxonId.setValue(
      await lastValueFrom(this.http.get<number>(`${environment.cprServiceApi}taxon/next`)),
    );
  }
}
