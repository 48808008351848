import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KnowledgeBaseLayoutComponent } from './layout/knowledge-base-layout.component';
import { KnowledgeBaseOverviewScreenComponent } from './screen/overview/knowledge-base-overview-screen.component';
import { KnowledgeBaseTaxonScreenComponent } from './screen/taxon/knowledge-base-taxon-screen.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        data: { breadcrumb: 'Knowledge Base' },
        component: KnowledgeBaseLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: KnowledgeBaseOverviewScreenComponent,
          },
          {
            path: 'create',
            pathMatch: 'full',
            data: { breadcrumb: 'Create' },
            component: KnowledgeBaseTaxonScreenComponent,
          },
          {
            path: ':id',
            pathMatch: 'full',
            data: { breadcrumbDynamic: 'id' },
            component: KnowledgeBaseTaxonScreenComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class KnowledgeBaseRoutingModule {}
