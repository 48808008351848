import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITableDataSource } from '@suvo-bi-lib';
import { BreadcrumbsService } from '@suvo-bi-core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { Subject } from 'rxjs';
import { TaxonLineageComponent } from '../../components/taxon-lineage/taxon-lineage.component';

@Component({
  template: `
    <suvo-core-loading-spinner *ngIf="!taxon"></suvo-core-loading-spinner>
    <ng-container *ngIf="taxon">
      <app-taxon-overview
        [isTopLevelTaxon]="false"
        [taxon]="taxon"
        (save)="onSave()"
      ></app-taxon-overview>
      <app-taxon-history *ngIf="taxon?.history" [taxon]="taxon"></app-taxon-history>
      <app-taxon-lineage #lineage [isTopLevelTaxon]="false" [taxon]="taxon"></app-taxon-lineage>
    </ng-container>
  `,
})
export class KnowledgeBaseTaxonScreenComponent implements OnInit, OnDestroy {
  @ViewChild('lineage') lineage: TaxonLineageComponent;
  private readonly unsubscribe$ = new Subject<void>();

  taxon: ITaxon;
  searchResults: ITableDataSource<ITaxon>;
  taxonId;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumb: BreadcrumbsService,
    private readonly taxaDirectory: TaxonService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (data) => {
      if (data.id) {
        this.taxonId = data.id;
        this.taxon = await this.taxaDirectory.getById(data.id);
        this.breadcrumb.setDynamicNameSwap(
          'id',
          this.taxon.taxonName + ` (${this.taxon.cprTaxonId})`,
        );
      } else {
        this.taxon = {
          taxonName: '',
          oldRankId: 1,
          isOnlyEverPlussed: false,
          rank: 'SPECIES',
        };
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSave() {
    this.taxon = await this.taxaDirectory.getById(this.taxonId);
    this.breadcrumb.setDynamicNameSwap('id', this.taxon.taxonName + ` (${this.taxon.cprTaxonId})`);

    this.lineage.refresh();
  }
}
