import { Component, Input } from '@angular/core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';

@Component({
  selector: 'app-taxon-history',
  standalone: true,
  imports: [CommonModule, MatCard, MatCardHeader, MatCardTitle, MatCardContent],
  templateUrl: './taxon-history.component.html',
  styleUrl: './taxon-history.component.scss',
})
export class TaxonHistoryComponent {
  @Input() taxon: ITaxon;
}
